import React, { FormEvent, memo, useRef, useState } from "react";
import ClintonElecSVG from "Images/Clinton_Logo.svg";
import { Button, ButtonTypes, Input } from "@clintonelec/react-storybook";
import "Components/Login/ForgotPasswordForm/ForgotPasswordForm.less";
import { NotificationType, notify } from "src/Notifications";
import { resetPassword } from "Data/API/User";
import { IResetPasswordArgs } from "Interfaces";
import { Navigate } from "react-router-dom";
import { escapeRegExp } from "lodash";
import { IconSize } from "Components/Icon";

interface IResetPasswordFormFields extends HTMLFormElement {
	password: HTMLInputElement;
	passwordConfirmation: HTMLInputElement;
}

const ForgotPasswordForm = () => {
	const [ password, setPassword ] = useState("");
	const [ submitted, setSubmitted ] = useState(false);
	const [ passwordChanged, setPasswordChanged ] = useState(false);
	const formRef = useRef<HTMLFormElement>(null);

	const renderNavigator = () => {
		if (!passwordChanged) {
			return null;
		}

		return <Navigate to="/" replace={ true } />;
	};

	const onSubmit = (event: FormEvent<IResetPasswordFormFields>) => {
		event.preventDefault();

		setSubmitted(true);

		const urlParams = new URLSearchParams(window.location.search);
		const payload: IResetPasswordArgs = {
			email: urlParams.get("email"),
			password: event?.currentTarget?.password?.value,
			passwordConfirmation: event?.currentTarget?.passwordConfirmation?.value,
			token: urlParams.get("token")
		};

		if (formRef.current.checkValidity()) {
			resetPassword(payload)
				.then(() => {
					notify(NotificationType.SUCCESS, "Password Changed Successfully");

					setPasswordChanged(true);
				})
				.catch((error) => {
					notify(NotificationType.ERROR, "Error Changing Password", error);
				});
		}
	};

	return (
		<div className="forgot-password-form">
			<img src={ ClintonElecSVG } className="logo" />
			<form onSubmit={ onSubmit } ref={ formRef } noValidate>
				<label>New Password</label>
				<Input
					name="password"
					password
					wrapClassName="not-last-input"
					required
					onUpdate={ setPassword }
					noValidate={ !submitted }
					validityMessage={ "Password is required" }
				/>
				<label>Confirm New Password</label>
				<Input
					name="passwordConfirmation"
					password
					required
					validator={ `^${ escapeRegExp(password) }$` }
					noValidate={ !submitted }
					validityMessage={ "Password must match" }
				/>
				<Button
					id="submit-button"
					className="submit-button"
					type={ ButtonTypes.PRIMARY }
					htmlType="submit"
					icon={ {
						name: "lock-keyhole",
						size: IconSize.SMALLER
					} }
				>
					Reset Password
				</Button>
			</form>
			{ renderNavigator() }
		</div>
	);
};

export default memo(ForgotPasswordForm);
