import "Components/UserManagementTable/UserManagementTable.less";
import { ColumnSortDirection, IColumn, Table } from "@clintonelec/react-storybook";
import React, { memo } from "react";
import { Waypoint } from "react-waypoint";
import { IUser } from "Interfaces";
import { getFilteredUsers, getUsersFetchable, getUsersOptions } from "Data/Selectors/User";
import CreateUserModal from "Components/CreateUserModal";
import Icon from "Components/Icon";
import { UserRoles, UserStatus } from "Data/Objects/UI";
import { getFilters } from "Data/Selectors/UI";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";

const UserManagementTable = () => {
	const users = useAppSelector(getFilteredUsers);
	const usersOptions = useAppSelector(getUsersOptions);
	const filters = useAppSelector(getFilters);
	const canFetchUsers = useAppSelector(getUsersFetchable);
	const dispatch = useAppDispatch();
	const fetchNextUserPage = () => dispatch(fetchNextUserPage());

	const renderWaypoint = () => {
		if (!canFetchUsers) {
			return null;
		}

		return (
			<Waypoint
				onEnter={ fetchNextUserPage } key={ usersOptions[ "page[number]" ] } />
		);
	};

	const renderUsername = (user: IUser) => {
		return (
			<CreateUserModal user={ user }>
				<span className="selectable-text">{ user.username }</span>
			</CreateUserModal>
		);
	};

	const renderStatus = (user: IUser) => {
		if (user.active) {
			return <Icon name="check" fixedWidth className="active-status" />;
		}

		return <Icon name="xmark" fixedWidth className="inactive-status" />;
	};

	const getColumns = (): IColumn[] => {
		const { USER_STATUS } = filters;
		const columns = [
			{
				title: "User ID",
				dataIndex: "username",
				key: "username",
				sortable: true,
				defaultSortOrder: ColumnSortDirection.SORT_ASC
			},
			{
				title: "Name",
				dataIndex: "name",
				key: "name",
				sortable: true
			},
			{
				title: "Email",
				dataIndex: "email",
				key: "email",
				sortable: false
			},
			{
				title: "Role",
				dataIndex: "role",
				key: "role",
				sortable: false
			}
		];

		if (USER_STATUS === UserStatus.ALL_USERS) {
			columns.push({
				title: "Status",
				dataIndex: "status",
				key: "status",
				sortable: false
			});
		}

		return columns;
	};

	const dataSource = users?.flatMap((user) => {
		const { id, name, email, admin } = user;

		return {
			key: id,
			username: renderUsername(user),
			name: name,
			email: email,
			role: admin ? UserRoles.ADMIN : UserRoles.USER,
			status: renderStatus(user)
		};
	});

	return (
		<div className="table-wrapper">
			<Table
				data={ dataSource ?? [] }
				columns={ getColumns() }
			/>
			{ renderWaypoint() }
		</div>
	);
};

export default memo(UserManagementTable);
