import React, { FormEvent, memo, useRef, useState } from "react";
import {
	Button, ButtonTypes, Csv, DateRangePicker, IconWeight, Input, Select, SelectComponent, Themes
} from "@clintonelec/react-storybook";
import "./OrdersSider.less";
import { IIndexSignature, IItem, ScanDateFilterOptions } from "Interfaces";
import { clearOrderFormAction, setSelectedItemsAction, setScanDateOptionAction } from "Data/Actions/UI";
import { getItemFilters, getSelectedItems } from "Data/Selectors/UI";
import { getActiveUser } from "Data/Selectors/User";
import { floorDate, formatDate } from "Data/Utils/Date";
import { getPartNumbersSelectOptions } from "Data/Selectors/PartNumbers";
import { isEqual } from "lodash";
import { setItemsOptionsAction } from "Data/Actions/Items";
import { getItemsOptions } from "Data/Selectors/Items";
import { defaultPaginatedStateOptions } from "Data/Objects/Redux";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";

const handleDatePickerChanged = (
	updateStartDate: (date: Date) => void, updateEndDate: (date: Date) => void
) => (dates: [ Date, Date ]) => {
	if (dates) {
		const [ start, end ] = dates;

		updateStartDate(start);
		updateEndDate(end);
	} else {
		updateStartDate(null);
		updateEndDate(null);
	}
};

const getCsvFormattedItems = (items: IItem[]) => {
	return items?.map(item => {
		const { scannedAt, serialNumber, order, partNumber, updater } = item;

		return {
			"Order Number": order.orderNumber,
			"Serial Number": serialNumber,
			"Part Number": partNumber,
			"Scan Date": formatDate(new Date(scannedAt), "MM/DD/YYYY h:mm A"),
			Updater: updater?.name,
		};
	});
};

interface IFormFields extends HTMLFormElement {
	orderNumber: HTMLInputElement;
	serialNumber: HTMLInputElement;
	partNumber: HTMLInputElement | RadioNodeList;
	scanDateOption: HTMLInputElement;
	serialDateLower: HTMLInputElement;
	serialDateUpper: HTMLInputElement;
	serialRangeLower: HTMLInputElement;
	serialRangeUpper: HTMLInputElement;
}

const OrdersSider = () => {
	const filters = useAppSelector(getItemFilters);
	const itemOptions = useAppSelector(getItemsOptions);
	const partNumbers = useAppSelector(getPartNumbersSelectOptions);
	const user = useAppSelector(getActiveUser);
	const selectedItems = useAppSelector(getSelectedItems);
	const dispatch = useAppDispatch();
	const setScanDateOption = (value: ScanDateFilterOptions) => dispatch(setScanDateOptionAction(value));
	const setItemOptions = (payload: IIndexSignature<string | number>) => dispatch(setItemsOptionsAction(payload));
	const clearOrderForm = () => dispatch(clearOrderFormAction());
	const clearSelected = () => dispatch(setSelectedItemsAction([]));
	const {
		serialDateLower,
		serialDateUpper,
		scannedAfter: storeScannedAfter,
		scannedBefore: storeScannedBefore,
		scanDateOption,
		orderNumber: storeOrderNumber,
		serialNumber: storeSerialNumber,
		selectedPartNumbers,
		serialRangeLower: storeSerialRangeLower,
		serialRangeUpper: storeSerialRangeUpper
	} = filters;

	const [ scannedAfter, setScannedAfter ] = useState(storeScannedAfter ? new Date(storeScannedAfter) : null);
	const [ scannedBefore, setScannedBefore ] = useState(storeScannedBefore ? new Date(storeScannedBefore) : null);
	const [ selectedScanDateOption, setSelectedScanDateOption ] = useState(scanDateOption);
	const [ startDate, setStartDate ] = useState(serialDateLower ? new Date(serialDateLower) : null);
	const [ endDate, setEndDate ] = useState(serialDateUpper ? new Date(serialDateUpper) : null);
	const [ orderNumber, setOrderNumber ] = useState(storeOrderNumber);
	const [ serialNumber, setSerialNumber ] = useState(storeSerialNumber);
	const [ serialRangeLower, setSerialRangeLower ] = useState(storeSerialRangeLower);
	const [ serialRangeUpper, setSerialRangeUpper ] = useState(storeSerialRangeUpper);
	const formRef = useRef<HTMLFormElement>(null);
	const partNumbersRef = useRef<SelectComponent>(null);
	const scanDateRef = useRef<SelectComponent>(null);

	const handleScanDateSelect = (value: string) => {
		const scanDateOption: ScanDateFilterOptions = typeof value === "string" ? parseInt(value) : value;

		setSelectedScanDateOption(scanDateOption);

		if (value === null) {
			setScannedAfter(null);
			setScannedBefore(null);
		} else if (scanDateOption !== ScanDateFilterOptions.CUSTOM) {
			setScannedAfter(floorDate(new Date(new Date().valueOf() - scanDateOption)));
			setScannedBefore(null);
		}
	};

	const handleSubmit = (event: FormEvent<IFormFields>) => {
		event.preventDefault();

		if (formRef.current.checkValidity()) {
			const partNumbers = partNumbersRef.current.getValue().map((option) => {
				return option.value;
			}) as string[];

			const scanDateOption = event.currentTarget.scanDateOption.value;
			const orderNumber = event.currentTarget.orderNumber.value;
			const partNumberString = partNumbers.join(",");
			const serialNumber = event.currentTarget.serialNumber.value;
			const serialRangeLower = event.currentTarget.serialRangeLower.value;
			const serialRangeUpper = event.currentTarget.serialRangeUpper.value;
			const itemOptionsPayload: IIndexSignature<string | number> = {
				...itemOptions,
				"filter[order]": orderNumber ? orderNumber : undefined,
				"filter[partNumber]": partNumberString ? partNumberString : undefined,
				"filter[scannedAfter]": scannedAfter ? scannedAfter.toISOString() : undefined,
				"filter[scannedBefore]": scannedBefore ? scannedBefore.toISOString() : undefined,
				"filter[serialNumber]": serialNumber ? serialNumber : undefined,
				"filter[dateCodeAfter]": startDate ? formatDate(startDate, "MMDDY") : undefined,
				"filter[dateCodeBefore]": endDate ? formatDate(endDate, "MMDDY") : undefined,
				"filter[sequenceAfter]": serialRangeLower ? serialRangeLower : undefined,
				"filter[sequenceBefore]": serialRangeUpper ? serialRangeUpper : undefined
			};

			Object.keys(itemOptionsPayload).forEach((key) => {
				if (itemOptionsPayload[key] === undefined) {
					delete itemOptionsPayload[key];
				}
			});

			if (isEqual(itemOptions, itemOptionsPayload)) {
				return;
			}

			itemOptionsPayload["page[number]"] = 1;

			clearSelected();
			setScanDateOption(scanDateOption ? parseInt(scanDateOption) : null);
			setItemOptions(itemOptionsPayload);
		}
	};

	const clearForm = (event: FormEvent<IFormFields>) => {
		event.preventDefault();

		partNumbersRef.current.clearValue();
		scanDateRef.current.clearValue();
		setOrderNumber("");
		setSerialNumber("");
		setSerialRangeLower("");
		setSerialRangeUpper("");

		clearState();

		if (isEqual(Object.keys(itemOptions), Object.keys(defaultPaginatedStateOptions))) {
			return;
		}

		clearOrderForm();
	};

	const clearState = () => {
		setScannedAfter(null);
		setScannedBefore(null);
		setSelectedScanDateOption(null);
		setStartDate(null);
		setEndDate(null);
	};

	const renderCustomScanDatePicker = () => {
		if (selectedScanDateOption !== ScanDateFilterOptions.CUSTOM) {
			return null;
		}

		return (
			<DateRangePicker
				block
				name="serialDateRange"
				className="scan-date-picker"
				cleanable
				defaultValue={ [ scannedAfter, scannedBefore ] }
				format="MM/dd/yyyy"
				onChange={ handleDatePickerChanged(setScannedAfter, setScannedBefore) }
			/>
		);
	};

	const renderExportButton = () => {
		const formattedItems = getCsvFormattedItems(selectedItems);
		const fileName = `${ user.username }_${ formatDate(new Date(), "MMDDYYYY h.mm A") }_Report.csv`;

		return (
			<Csv data={ formattedItems } fileName={ fileName }>
				<Button
					type={ ButtonTypes.SECONDARY }
					ghost
					disabled={ !selectedItems.length }
					theme={ Themes.DARK }
					icon={ {
						name: "download",
						weight: IconWeight.REGULAR
					} }
				>
					Export Selected
				</Button>
			</Csv>
		);
	};

	return (
		<div className="orders-sider cec-sider left-sidebar">
			<div className="orders-sider-header">
				{ "Pick 'N Ship" }
			</div>
			<form
				className="orders-sider-body"
				onSubmit={ handleSubmit }
				ref={ formRef }
				noValidate
			>
				<div className="orders-sider-content">
					<div className="sider-block">
						<div>Order Number:</div>
						<Input
							name="orderNumber"
							value={ orderNumber }
							onUpdate={ setOrderNumber }
							type="search"
						/>
					</div>
					<div className="sider-block">
						<div>Serial Number:</div>
						<Input
							name="serialNumber"
							value={ serialNumber }
							onUpdate={ setSerialNumber }
							type="search"
						/>
					</div>
					<div className="sider-block">
						<div>Part Number:</div>
						<Select
							name="partNumber"
							forwardRef={ partNumbersRef }
							mode="tags"
							options={ partNumbers }
							defaultValue={ selectedPartNumbers }
						/>
					</div>
					<div className="sider-block">
						<div className="label">Serial Date Range</div>
						<DateRangePicker
							block
							className="date-picker"
							cleanable
							value={ [ startDate, endDate ] }
							format="MM/dd/yyyy"
							name="serialDateRange"
							onChange={ handleDatePickerChanged(setStartDate, setEndDate) }
						/>
					</div>
					<div className="sider-block">
						<div className="label">Serial Number Range (last 4 characters)</div>
						<div className="range-inputs">
							<Input
								name="serialRangeLower"
								placeholder="From"
								type="number"
								min={ 0 }
								max={ 9999 }
								hideCharMessage
								value={ serialRangeLower }
								onUpdate={ setSerialRangeLower }
							/>
							<Input
								name="serialRangeUpper"
								placeholder="To"
								type="number"
								min={ 0 }
								max={ 9999 }
								hideCharMessage
								value={ serialRangeUpper }
								onUpdate={ setSerialRangeUpper }
							/>
						</div>
					</div>
					<div className="sider-block">
						<div className="label">Scan Date</div>
						<Select
							name="scanDateOption"
							options={
								[
									{ value: ScanDateFilterOptions.TODAY, label: "Today" },
									{ value: ScanDateFilterOptions.WEEK, label: "Last Week" },
									{ value: ScanDateFilterOptions.THIRTY_DAYS, label: "Last 30 Days"  },
									{ value: ScanDateFilterOptions.NINETY_DAYS, label: "Last 90 Days" },
									{ value: ScanDateFilterOptions.YEAR, label: "Last Year" },
									{ value: ScanDateFilterOptions.CUSTOM, label: "Custom" }
								]
							}
							forwardRef={ scanDateRef }
							defaultValue={ selectedScanDateOption }
							allowClear
							onSelect={ handleScanDateSelect }
						/>
						{ renderCustomScanDatePicker() }
					</div>
				</div>
				<div className="orders-sider-footer">
					<Button
						icon={ {
							name: "magnifying-glass",
							weight: IconWeight.REGULAR
						} }
						htmlType="submit"
					>
						Submit
					</Button>
					<Button
						icon={ {
							name: "eraser",
							weight: IconWeight.REGULAR
						} }
						type={ ButtonTypes.DANGER }
						ghost
						theme={ Themes.DARK }
						onClick={ clearForm }
					>
						Clear
					</Button>
					{ renderExportButton() }
				</div>
			</form>
		</div>
	);
};

export default memo(OrdersSider);
