import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { deserializeJsonApiData } from "Data/Utils/Json";
import { IOrder, IWithApiData } from "Interfaces";
import * as ItemsApi from "Data/API/Items";
import { setItemsOptionsAction } from "Data/Actions/Items";
import { createAsyncThunk } from "Data/Redux/Store";
import { getAllItems, getFilteredItems, getItemsOptions } from "Data/Selectors/Items";
import { NotificationType, notify } from "src/Notifications";
import React from "react";
import { isEqual } from "lodash";
import { defaultPaginatedStateOptions } from "Data/Objects/Redux";

const {
	FETCH_ITEMS_PUSHER, DELETE_ORDER
} = ACTION_TYPES.Pusher;

const notificationContent = (thunkApi) => {
	const resetTableFetching = () => thunkApi.dispatch(
		setItemsOptionsAction({
			...getItemsOptions(thunkApi.getState()),
			"page[number]": 1
		})
	);

	const anchor = React.createElement(
		"a",
		{ onClick: resetTableFetching, key: "refresh-table", className: "selectable-text" },
		"here"
	);

	return React.createElement("div", null, [ "Click ", anchor, " to refresh table and get new content." ]);
};

export const createOrderAndFetchItems = createAsyncThunk(
	FETCH_ITEMS_PUSHER,
	async (order: IWithApiData, thunkApi) => {
		const itemOptions = getItemsOptions(thunkApi.getState());

		// do nothing if no filters are applied
		if (isEqual(itemOptions, defaultPaginatedStateOptions)) {
			return;
		}

		return deserializeJsonApiData<Partial<IOrder>>(order).then((deserialized: Partial<IOrder>) => {
			const orderArray = Array.isArray(deserialized) ? deserialized : [ deserialized ];

			return Promise
				.all(orderArray.map((order) => {
					return ItemsApi.getItems({ "filter[order]": order.orderNumber }).then((response) => {
						return response.data;
					});
				}))
				.then((unflattenedItems) => {
					const items = unflattenedItems.flat();
					const filteredItemIds = getFilteredItems(thunkApi.getState(), items)
						.map((item) => item.id);

					const newData = filteredItemIds.length > 0;

					if (newData) {
						notify(
							NotificationType.INFO,
							"New Data Matching Filters",
							notificationContent(thunkApi),
							false,
							{
								position: "bottom-right",
								autoClose: false,
								toastId: "new-table-data",
								className: "new-table-data-notification"
							}
						);
					}
				});
		});
	}
);

export const updateOrderAndFilterItems = createAsyncThunk(
	FETCH_ITEMS_PUSHER,
	async (order: IWithApiData, thunkApi) => {
		const itemOptions = getItemsOptions(thunkApi.getState());

		// do nothing if no filters are applied
		if (isEqual(itemOptions, defaultPaginatedStateOptions)) {
			return;
		}

		deserializeJsonApiData<Partial<IOrder>>(order).then((deserialized: Partial<IOrder>) => {
			const orderArray = Array.isArray(deserialized) ? deserialized : [ deserialized ];
			const orderIds = orderArray.map((order) => order.id);

			return Promise
				.all(orderArray.map((order) => {
					return ItemsApi.getItems({ "filter[order]": order.orderNumber }).then((response) => {
						return response.data;
					});
				}))
				.then((unflattenedItems) => {
					const items = unflattenedItems.flat();
					const relevantStateItems = getAllItems(thunkApi.getState()).filter((item) => {
						return orderIds.includes(item?.order?.id);
					}).map((item) => item.id).sort();

					const filteredItemIds = getFilteredItems(thunkApi.getState(), items)
						.map((item) => item.id).sort();

					const newData = filteredItemIds.length > 0 && !isEqual(relevantStateItems, filteredItemIds);

					if (newData) {
						notify(
							NotificationType.INFO,
							"New Data Matching Filters",
							notificationContent(thunkApi),
							false,
							{
								position: "bottom-right",
								autoClose: false,
								toastId: "new-table-data",
								className: "new-table-data-notification"
							}
						);
					}
				});
		});
	}
);

export const deleteOrderLocal = createAsyncThunk(
	DELETE_ORDER,
	async (id: string, thunkApi) => {
		const newData = getAllItems(thunkApi.getState()).some((item) => {
			return item?.order?.id === id;
		});

		if (newData) {
			notify(
				NotificationType.INFO,
				"New Data Matching Filters",
				notificationContent(thunkApi),
				false,
				{
					position: "bottom-right",
					autoClose: false,
					toastId: "new-table-data",
					className: "new-table-data-notification"
				}
			);
		}
	}
);
