import "Components/ItemTable/ItemTable.less";
import { ColumnSortDirection, IColumn, IWithKey, Popover, ProgressBar, Table } from "@clintonelec/react-storybook";
import React, { memo, useEffect } from "react";
import { DateUnits, diffDates, floorDate, formatDate } from "Data/Utils/Date";
import Icon, { IconSize } from "Components/Icon";
import { getAllItems, getItemsFetchable, getItemsOptions, getItemsProgress } from "Data/Selectors/Items";
import { fetchNextItemsPageAction } from "Data/Actions/Items";
import { setSelectedItemsAction } from "Data/Actions/UI";
import { getSelectedItemIds } from "Data/Selectors/UI";
import { isEqual } from "lodash";
import { defaultPaginatedStateOptions } from "Data/Objects/Redux";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";

const ItemTable = () => {
	const items = useAppSelector(getAllItems);
	const itemsOptions = useAppSelector(getItemsOptions);
	const canFetchItems = useAppSelector(getItemsFetchable);
	const itemsProgress = useAppSelector(getItemsProgress);
	const selectedIds = useAppSelector(getSelectedItemIds);
	const dispatch = useAppDispatch();
	const fetchNextItemsPage = () => dispatch(fetchNextItemsPageAction());
	const setSelected = (keys: string[]) => dispatch(setSelectedItemsAction(keys));

	const fetchItems = () => {
		const hasOptions = !isEqual(itemsOptions, defaultPaginatedStateOptions);

		if (hasOptions && canFetchItems) {
			fetchNextItemsPage();
		}
	};

	const sortDateColumn = (data: IWithKey[], dataIndex: string, direction: ColumnSortDirection) => {
		let sortedData = data;

		if (direction !== ColumnSortDirection.SORT_NONE) {
			sortedData = [ ...data ].sort((value, nextValue) => {
				const date = new Date(value[dataIndex]);
				const nextDate = new Date(nextValue[dataIndex]);

				if (isNaN(date.valueOf())) {
					return direction === ColumnSortDirection.SORT_DESC ? -1 : 1;
				}

				if (isNaN(nextDate.valueOf())) {
					return direction === ColumnSortDirection.SORT_DESC ? 1 : -1;
				}

				if (date > nextDate) {
					return 1;
				}

				if (date < nextDate) {
					return -1;
				}

				return 0;
			});

			if (direction === ColumnSortDirection.SORT_DESC) {
				sortedData.reverse();
			}
		}

		return sortedData;
	};

	useEffect(() => {
		fetchItems();
	});

	const renderWarrantyExpiration = (createdAt: Date) => {
		const warrantyExpiration = floorDate(new Date(createdAt.valueOf() + 3 * DateUnits.YEARS));
		const today = floorDate(new Date());
		const dateDiff = diffDates(warrantyExpiration, today, DateUnits.YEARS);
		let statusMessage = "The warranty period on this item has expired.";
		let statusIcon = "times-circle";
		let statusIconClass = "not-downloaded";

		if (dateDiff >= 2) {
			statusMessage = "This item qualifies for Advance Replacement.";
			statusIcon = "check-circle";
			statusIconClass = "downloaded";
		} else if (dateDiff >= 0) {
			statusMessage = "This item qualifies for Repair.";
			statusIcon = "exclamation-circle";
			statusIconClass = "downloading";
		}

		return (
			<div data-value={ warrantyExpiration }>
				<Popover content={ statusMessage }>
					<Icon
						name={ statusIcon }
						className={ statusIconClass + " status-icon" }
						size={ IconSize.SMALLER }
					/>
				</Popover>
				{
					formatDate(
						warrantyExpiration,
						"MM/DD/YYYY"
					)
				}
			</div>
		);
	};

	const loader = (
		<ProgressBar progress={ itemsProgress } />
	);

	const dataSource = (
		items?.flatMap(item => {
			const { scannedAt, serialNumber, order, partNumber, updater, id } = item;
			const scannedAtObject = new Date(scannedAt);

			return {
				key: id,
				orderNumber: order.orderNumber,
				serialNumber: serialNumber,
				partNumber: partNumber,
				scanDate: formatDate(scannedAtObject, "MM/DD/YYYY h:mm A"),
				updater: updater?.name,
				warrantyExpiration: renderWarrantyExpiration(scannedAtObject)
			};

		})
	);

	const columns: IColumn[] = [
		{
			title: "Order Number",
			dataIndex: "orderNumber",
			key: "orderNumber",
			sortable: true
		},
		{
			title: "Serial Number",
			dataIndex: "serialNumber",
			key: "serialNumber",
			sortable: true
		},
		{
			title: "Part Number",
			dataIndex: "partNumber",
			key: "partNumber",
			sortable: true
		},
		{
			title: "Scan Date",
			dataIndex: "scanDate",
			key: "scanDate",
			sortable: true,
			customSorter: sortDateColumn,
			defaultSortOrder: ColumnSortDirection.SORT_DESC
		},
		{
			title: "Updated By",
			dataIndex: "updater",
			key: "updater",
			sortable: true
		},
		{
			title: "Warranty Expiration",
			dataIndex: "warrantyExpiration",
			key: "warrantyExpiration",
			sortable: true
		}
	];

	return (
		<div className="table-wrapper">
			<Table
				data={ dataSource ?? [] }
				columns={ columns }
				selectModeOn={ true }
				onSelect={ setSelected }
				selected={ selectedIds }
				loading={ canFetchItems }
				loader={ loader }
				pageSize={ 100 }
				stickyHeader
				stickyFooter
			/>
		</div>
	);
};

export default memo(ItemTable);
