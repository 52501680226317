import { Filters } from "Data/Objects/UI";
import { DateUnits } from "Data/Utils/Date";

export type DateString = string;

export interface IItemFilters {
	orderNumber: string;
	scannedAfter?: DateString;
	scannedBefore?: DateString;
	scanDateOption: ScanDateFilterOptions;
	selectedPartNumbers: string[];
	serialDateLower: DateString;
	serialDateUpper: DateString;
	serialNumber: string;
	serialRangeLower: string;
	serialRangeUpper: string;
}

export enum ScanDateFilterOptions {
	TODAY = DateUnits.DAYS,
	WEEK = 7 * DateUnits.DAYS,
	THIRTY_DAYS = 30 * DateUnits.DAYS,
	NINETY_DAYS = 90 * DateUnits.DAYS,
	YEAR = 365 * DateUnits.DAYS,
	CUSTOM = 0
}

export interface ISetFilterPayload {
	filter: Filters;
	value: string;
}

export type IFilters = {
	[ key in Filters ]: string;
}
