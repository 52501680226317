import React, { Component } from "react";

interface IErrorBoundaryProps {
	children: React.ReactNode
}

interface IErrorBoundaryViewState {
	error: Error,
	errorInfo: React.ErrorInfo,
	hasError: boolean
}

class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryViewState> {
	constructor(props) {
		super(props);

		this.state ={
			error: null,
			errorInfo: null,
			hasError: false
		};
	}

	static getDerivedStateFromError(error: Error) {
		return { error: error };
	}

	componentDidCatch( error: Error, errorInfo: React.ErrorInfo ): void {
		this.setState({
			error: error,
			errorInfo: errorInfo,
			hasError: true
		});
		console.log( "error: " + error.name );
	}

	render() {
		if ( this.state.hasError ) {
			return (
				<div>
					<h1> Something went wrong. </h1>
					<details>
						{ this.state.error && this.state.error.toString() }
						<br />
						{ this.state.errorInfo.componentStack }
					</details>
				</div>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
